import type { SVGProps } from "react";

export const Eye: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.846 15.5C19.846 17.975 17.846 19.975 15.371 19.975C12.896 19.975 10.896 17.975 10.896 15.5C10.896 13.025 12.896 11.025 15.371 11.025C17.846 11.025 19.846 13.025 19.846 15.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M15.3709 25.8375C19.7834 25.8375 23.8959 23.2375 26.7584 18.7375C27.8834 16.975 27.8834 14.0125 26.7584 12.25C23.8959 7.75002 19.7834 5.15002 15.3709 5.15002C10.9584 5.15002 6.8459 7.75002 3.9834 12.25C2.8584 14.0125 2.8584 16.975 3.9834 18.7375C6.8459 23.2375 10.9584 25.8375 15.3709 25.8375Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
