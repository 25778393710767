import type { SVGProps } from "react";

export const Logout: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4961 9.94999C11.8836 5.44999 14.1961 3.61249 19.2586 3.61249H19.4211C25.0086 3.61249 27.2461 5.84999 27.2461 11.4375V19.5875C27.2461 25.175 25.0086 27.4125 19.4211 27.4125H19.2586C14.2336 27.4125 11.9211 25.6 11.5086 21.175"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.121 15.5H4.896"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.68359 11.3125L3.49609 15.5L7.68359 19.6875"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
