import type { SVGProps } from "react";

export const Twitter: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 43 43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_122_753)">
      <path
        d="M43.0534 9.39281C41.4644 10.0819 39.7612 10.5445 37.9706 10.7545C39.7981 9.68484 41.2023 7.99172 41.8608 5.97703C40.1542 6.96469 38.2562 7.68328 36.2439 8.07047C34.6314 6.39047 32.3336 5.34375 29.7905 5.34375C24.906 5.34375 20.9453 9.20906 20.9453 13.98C20.9453 14.6559 21.0259 15.3155 21.1771 15.9487C13.8268 15.5878 7.31297 12.1491 2.94914 6.92203C2.18656 8.20172 1.7532 9.68484 1.7532 11.2664C1.7532 14.2622 3.31195 16.9069 5.68703 18.4589C4.23578 18.413 2.87188 18.0225 1.6793 17.3761C1.6793 17.4122 1.6793 17.4483 1.6793 17.4844C1.6793 21.6712 4.72961 25.1592 8.77094 25.9566C8.03188 26.1534 7.24914 26.2584 6.44289 26.2584C5.8718 26.2584 5.3175 26.2059 4.77664 26.1009C5.90539 29.5298 9.1707 32.0334 13.0373 32.1023C10.0139 34.4189 6.20102 35.797 2.05555 35.797C1.34 35.797 0.637891 35.7577 -0.0507812 35.6756C3.85953 38.13 8.50891 39.5573 13.5043 39.5573C29.7704 39.5573 38.666 26.3962 38.666 14.9841C38.666 14.61 38.6559 14.2327 38.6425 13.8652C40.3692 12.6478 41.8675 11.1253 43.0534 9.39281Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_122_753">
        <rect
          height="42"
          width="43"
          fill="white"
          transform="translate(0 0.625488)"
        />
      </clipPath>
    </defs>
  </svg>
);
