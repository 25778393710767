import type { SVGProps } from "react";

export const Moon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.15846 16.5033C3.60846 22.9408 9.07096 28.1783 15.6085 28.4658C20.221 28.6658 24.346 26.5158 26.821 23.1283C27.846 21.7408 27.296 20.8158 25.5835 21.1283C24.746 21.2783 23.8835 21.3408 22.9835 21.3033C16.871 21.0533 11.871 15.9408 11.846 9.90335C11.8335 8.27835 12.171 6.74084 12.7835 5.34084C13.4585 3.79084 12.646 3.05335 11.0835 3.71585C6.13346 5.80334 2.74596 10.7908 3.15846 16.5033Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
