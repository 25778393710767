import type { SVGProps } from "react";

export const Download: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_829_912)">
      <path
        d="M9.91406 11.5969V17.5969L11.9141 15.5969"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91406 17.5969L7.91406 15.5969"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9141 10.5969V15.5969C22.9141 20.5969 20.9141 22.5969 15.9141 22.5969H9.91406C4.91406 22.5969 2.91406 20.5969 2.91406 15.5969V9.59692C2.91406 4.59692 4.91406 2.59692 9.91406 2.59692H14.9141"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9141 10.5969H18.9141C15.9141 10.5969 14.9141 9.59692 14.9141 6.59692V2.59692L22.9141 10.5969Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_829_912">
        <rect fill="white" transform="translate(0.914062 0.596924)" />
      </clipPath>
    </defs>
  </svg>
);
