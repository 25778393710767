import type { SVGProps } from "react";

export const AxisX: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 26 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_30_10691)">
      <path
        d="M0.914062 39.0813V38.2653L2.64207 37.8333C3.05807 37.7053 3.39407 37.5453 3.65007 37.3533C3.90607 37.1613 4.14607 36.9373 4.37007 36.6813L10.6101 27.9933V30.0573L4.32207 20.0253C4.13007 19.7693 3.93807 19.5613 3.74607 19.4013C3.55407 19.2413 3.26607 19.1133 2.88207 19.0173L1.39406 18.6813V17.8653H13.874V18.6813L12.386 19.0653C11.81 19.1933 11.442 19.4013 11.282 19.6893C11.154 19.9773 11.186 20.2973 11.378 20.6493L15.458 27.5133L13.25 28.2813L18.242 20.6493C18.53 20.2333 18.674 19.8973 18.674 19.6413C18.706 19.3853 18.45 19.2093 17.906 19.1133L15.794 18.6813V17.8653H24.146V18.6813L22.61 19.1133C22.194 19.2413 21.842 19.4013 21.554 19.5933C21.298 19.7533 21.074 19.9773 20.882 20.2653L14.786 28.8093L15.074 26.6973L21.794 36.6813C21.986 36.9373 22.226 37.1933 22.514 37.4493C22.802 37.6733 23.138 37.8333 23.522 37.9293L25.01 38.2653V39.0813H12.386V38.2653L13.394 38.0253C14.258 37.8333 14.786 37.5773 14.978 37.2573C15.202 36.9373 15.17 36.5533 14.882 36.1053L9.84207 28.2813L12.434 28.3293L7.10607 36.2973C6.81807 36.7133 6.67407 37.0493 6.67407 37.3053C6.67407 37.5613 6.93007 37.7373 7.44207 37.8333L9.55407 38.2653V39.0813H0.914062Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_30_10691">
        <rect
          width="24.096"
          height="55"
          fill="white"
          transform="translate(0.914062 0.973267)"
        />
      </clipPath>
    </defs>
  </svg>
);
