import type { SVGProps } from "react";

export const DropdownFill: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="-8 -3 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5853 7.2893L7.93337 2.63738C7.38398 2.088 6.48499 2.088 5.93561 2.63738L1.28369 7.2893"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.28385 17.2893L5.93577 21.9412C6.48516 22.4906 7.38415 22.4906 7.93353 21.9412L12.5854 17.2893"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
