import type { SVGProps } from "react";

export const AxisY: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 26 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_210_362)">
      <path
        d="M4.56207 45.3213C3.44207 45.3213 2.59407 44.9853 2.01807 44.3133C1.44206 43.6413 1.15406 42.9213 1.15406 42.1533C1.15406 41.2893 1.41006 40.5373 1.92207 39.8973C2.46607 39.2893 3.15407 38.9853 3.98607 38.9853C4.49807 38.9853 4.93007 39.0653 5.28207 39.2253C5.63407 39.3853 5.92207 39.5933 6.14607 39.8493C6.37007 40.0733 6.56207 40.2333 6.72207 40.3293C6.88207 40.4253 7.04207 40.4733 7.20207 40.4733C7.68207 40.4733 8.13007 40.0093 8.54607 39.0813L11.5701 32.3613L3.84207 13.7853C3.71407 13.4973 3.53807 13.2893 3.31407 13.1613C3.09007 13.0013 2.78607 12.8733 2.40207 12.7773L0.914062 12.4413V11.6253H12.7701V12.4413L11.7621 12.6813C11.2501 12.8093 10.8341 13.0333 10.5141 13.3533C10.1941 13.6733 10.1461 14.1533 10.3701 14.7933L15.2661 27.5133H13.9701L19.6821 14.3613C19.8741 13.9133 19.9061 13.5773 19.7781 13.3533C19.6821 13.0973 19.3621 12.9213 18.8181 12.8253L16.6581 12.4413V11.6253H25.4901V12.4413L23.5221 13.0173C23.1061 13.1453 22.8021 13.2893 22.6101 13.4493C22.4181 13.5773 22.2581 13.7853 22.1301 14.0733L10.2741 40.2813C9.66607 41.6573 9.02607 42.6973 8.35407 43.4013C7.71407 44.1373 7.07407 44.6333 6.43407 44.8893C5.79407 45.1773 5.17007 45.3213 4.56207 45.3213Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_210_362">
        <rect
          width="24.576"
          height="55"
          fill="white"
          transform="translate(0.914062 0.973267)"
        />
      </clipPath>
    </defs>
  </svg>
);
