import type { SVGProps } from "react";

export const Check: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_715_1176)">
      <path
        d="M10.6152 2.05879C10.4475 1.89044 10.1725 1.89044 10.0047 2.05879L4.92622 7.13605L2.31082 4.50276C2.14308 4.33378 1.86988 4.33378 1.70091 4.50276L0.784497 5.41855C0.616756 5.58568 0.616756 5.86072 0.784497 6.02908L4.61849 9.88836C4.78623 10.0555 5.05943 10.0555 5.22902 9.88836L11.531 3.58573C11.7012 3.41737 11.7012 3.14171 11.531 2.97274L10.6152 2.05879Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_715_1176">
        <rect
          width="11"
          height="11"
          fill="white"
          transform="translate(0.658691 0.473267)"
        />
      </clipPath>
    </defs>
  </svg>
);
