import type { SVGProps } from "react";

export const Link: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.22703 12.1973C3.43703 11.2473 2.95703 10.0273 2.95703 8.69727C2.95703 5.67727 5.42703 3.19727 8.45703 3.19727H13.457C16.477 3.19727 18.957 5.67727 18.957 8.69727C18.957 11.7173 16.487 14.1973 13.457 14.1973H10.957"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M21.687 12.1973C22.477 13.1473 22.957 14.3673 22.957 15.6973C22.957 18.7173 20.487 21.1973 17.457 21.1973H12.457C9.43703 21.1973 6.95703 18.7173 6.95703 15.6973C6.95703 12.6773 9.42703 10.1973 12.457 10.1973H14.957"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
