import type { SVGProps } from "react";

export const Health: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.127 28H19.627C25.877 28 28.377 25.5 28.377 19.25V11.75C28.377 5.5 25.877 3 19.627 3H12.127C5.87695 3 3.37695 5.5 3.37695 11.75V19.25C3.37695 25.5 5.87695 28 12.127 28Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M3.37695 16.375L10.877 16.35C11.8145 16.35 12.8645 17.0625 13.2145 17.9375L14.6395 21.5375C14.9645 22.35 15.477 22.35 15.802 21.5375L18.6645 14.275C18.9395 13.575 19.452 13.55 19.802 14.2125L21.102 16.675C21.4895 17.4125 22.4895 18.0125 23.3145 18.0125H28.3895"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
