import type { SVGProps } from "react";

export const EditFill: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.883 3.043.252-.252c1.125-1.125 2.425-1.65 4.075.012 1.65 1.65 1.113 2.95 0 4.063l-.252.252a1.244 1.244 0 0 0-.56-.331A4.61 4.61 0 0 1 9.204 3.59a1.244 1.244 0 0 0-.321-.548Zm-1.87 1.87L2.647 9.279c-.25.25-.488.738-.538 1.088l-.35 2.475c-.125.9.5 1.525 1.4 1.4l2.475-.35c.35-.05.838-.288 1.088-.538l4.368-4.368a7.113 7.113 0 0 1-4.079-4.071Z"
      clipRule="evenodd"
    />
  </svg>
);
