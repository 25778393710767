import type { SVGProps } from "react";

export const Mail: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 33"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.696 32.595h18.75c5.625 0 9.375-2.812 9.375-9.375V10.095c0-6.562-3.75-9.375-9.375-9.375H9.696C4.071.72.321 3.533.321 10.095V23.22c0 6.563 3.75 9.375 9.375 9.375zm19.374-20.78a1 1 0 00-1.248-1.564l-5.867 4.687c-1.567 1.247-4.218 1.247-5.785 0l-5.848-4.686a1 1 0 00-1.251 1.561l5.85 4.688.002.002c2.296 1.827 5.982 1.827 8.277 0l.002-.001 5.868-4.688z"
      clipRule="evenodd"
    ></path>
  </svg>
);
