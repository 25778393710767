import type { SVGProps } from "react";

export const Dropdown: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 -2 8 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.09606 3.00375L6.57106 0.528748L7.27806 1.23575L4.09606 4.41775L0.914062 1.23575L1.62106 0.528748L4.09606 3.00375Z"
      fill="currentColor"
    />
  </svg>
);
