import type { SVGProps } from "react";

export const Duplicate: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M8 15.961c-4.073-.236-5-1.65-5-6.461C3 4.147 4.147 3 9.5 3c4.81 0 6.225.927 6.461 5"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M8 14.5C8 9.147 9.147 8 14.5 8S21 9.147 21 14.5 19.853 21 14.5 21 8 19.853 8 14.5Z"
    />
  </svg>
);
