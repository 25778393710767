import type { SVGProps } from "react";

export const Edit: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1211 3H11.6211C5.37109 3 2.87109 5.5 2.87109 11.75V19.25C2.87109 25.5 5.37109 28 11.6211 28H19.1211C25.3711 28 27.8711 25.5 27.8711 19.25V16.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
    <path
      d="M20.4209 4.27501L10.5709 14.125C10.1959 14.5 9.82094 15.2375 9.74594 15.775L9.20844 19.5375C9.00844 20.9 9.97094 21.85 11.3334 21.6625L15.0959 21.125C15.6209 21.05 16.3584 20.675 16.7459 20.3L26.5959 10.45C28.2959 8.75001 29.0959 6.77501 26.5959 4.27501C24.0959 1.77501 22.1209 2.57501 20.4209 4.27501Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2.5"
    />
    <path
      d="M19.0088 5.6875C19.8463 8.675 22.1838 11.0125 25.1838 11.8625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2.5"
    />
  </svg>
);
