import type { SVGProps } from "react";

export const DropdownBigDown: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_122_601)">
      <path
        d="M6.45739 7.08602L8.93239 4.61102L9.63939 5.31802L6.45739 8.50002L3.27539 5.31802L3.98239 4.61102L6.45739 7.08602Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_122_601">
        <rect
          height="12"
          width="12"
          fill="white"
          transform="translate(0.457031 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
