import type { SVGProps } from "react";

export const Closesquare: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54102 14.83L15.201 9.16998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M15.201 14.83L9.54102 9.16998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M9.37109 22H15.3711C20.3711 22 22.3711 20 22.3711 15V9C22.3711 4 20.3711 2 15.3711 2H9.37109C4.37109 2 2.37109 4 2.37109 9V15C2.37109 20 4.37109 22 9.37109 22Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
