import type { SVGProps } from "react";

export const ArrowDrawn: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 415.262 415.261"
    {...props}
    fill="currentColor"
  >
    <path d="M414.937 374.984c-7.956-24.479-20.196-47.736-30.601-70.992-1.224-3.06-6.12-3.06-7.956-1.224-10.403 11.016-22.031 22.032-28.764 35.496h-.612c-74.664 5.508-146.88-58.141-198.288-104.652-59.364-53.244-113.22-118.116-134.64-195.84-1.224-9.792-2.448-20.196-2.448-30.6 0-4.896-6.732-4.896-7.344 0v5.508C1.836 12.68 0 14.516 0 17.576c.612 6.732 2.448 13.464 3.672 20.196C8.568 203.624 173.808 363.356 335.376 373.76c-5.508 9.792-10.403 20.195-16.523 29.988-3.061 4.283 1.836 8.567 6.12 7.955 30.6-4.283 58.14-18.972 86.292-29.987 2.447-.612 4.895-3.06 3.672-6.732zm-82.009 24.48c3.673-7.956 6.12-15.912 10.404-23.868 1.225-3.061-.612-5.508-2.448-6.12 0-1.836-1.224-3.061-3.06-3.672-146.268-24.48-264.996-124.236-309.06-259.489 28.764 53.244 72.828 99.756 116.28 138.924 31.824 28.765 65.484 54.468 102.204 75.888 28.764 16.524 64.872 31.824 97.92 21.421-1.836 4.896 5.508 7.344 7.956 3.672 7.956-10.404 15.912-20.196 24.48-29.376 8.567 18.972 17.748 37.943 24.479 57.527-22.643 8.569-45.287 19.585-69.155 25.093z" />
  </svg>
);
