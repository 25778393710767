import type { SVGProps } from "react";

export const Moresquare: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 54 54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7889 49.5566H34.0389C45.0806 49.5566 49.4972 45.1399 49.4972 34.0983V20.8483C49.4972 9.80659 45.0806 5.38992 34.0389 5.38992H20.7889C9.74723 5.38992 5.33057 9.80659 5.33057 20.8483V34.0983C5.33057 45.1399 9.74723 49.5566 20.7889 49.5566Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
    <path
      d="M30.7143 23.7441L27.9976 21.0274C27.6768 20.7066 27.1518 20.7066 26.8309 21.0274L24.1143 23.7441"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.8"
    />
    <path
      d="M30.7143 31.2024L27.9976 33.9191C27.6768 34.2399 27.1518 34.2399 26.8309 33.9191L24.1143 31.2024"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.8"
    />
  </svg>
);
