import type { SVGProps } from "react";

export const Logolightblue: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 153 33"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81589 25.5538V11.2498L13.2959 25.5538H14.9999V8.36976H13.2959V22.6738L3.83989 8.36976H2.08789V25.5538H3.81589Z"
      fill="currentColor"
    />
    <path
      d="M18.0213 19.7458C18.0213 23.2738 20.5173 25.8418 23.9253 25.8418C27.3333 25.8418 29.8293 23.2738 29.8293 19.7458C29.8293 16.1938 27.3333 13.6258 23.9253 13.6258C20.5173 13.6258 18.0213 16.1938 18.0213 19.7458ZM19.7013 19.7218C19.7013 17.0338 21.4293 15.1138 23.9253 15.1138C26.3973 15.1138 28.1493 17.0338 28.1493 19.7218C28.1493 22.4578 26.3973 24.3538 23.9253 24.3538C21.4293 24.3538 19.7013 22.4578 19.7013 19.7218Z"
      fill="currentColor"
    />
    <path
      d="M35.0247 25.5538V15.3298H37.3527V13.9378H35.0247V10.2658H33.3927V13.9378H31.0647V15.3298H33.3927V25.5538H35.0247Z"
      fill="currentColor"
    />
    <path
      d="M40.3251 10.7458C40.9971 10.7458 41.5731 10.1938 41.5731 9.52176C41.5731 8.84976 40.9971 8.27376 40.3251 8.27376C39.6531 8.27376 39.1011 8.84976 39.1011 9.52176C39.1011 10.1938 39.6531 10.7458 40.3251 10.7458ZM39.5091 25.5538H41.1651V13.9378H39.5091V25.5538Z"
      fill="currentColor"
    />
    <path
      d="M43.8025 19.7458C43.8025 23.2738 46.2985 25.8418 49.7065 25.8418C53.1145 25.8418 55.6105 23.2738 55.6105 19.7458C55.6105 16.1938 53.1145 13.6258 49.7065 13.6258C46.2985 13.6258 43.8025 16.1938 43.8025 19.7458ZM45.4825 19.7218C45.4825 17.0338 47.2105 15.1138 49.7065 15.1138C52.1785 15.1138 53.9305 17.0338 53.9305 19.7218C53.9305 22.4578 52.1785 24.3538 49.7065 24.3538C47.2105 24.3538 45.4825 22.4578 45.4825 19.7218Z"
      fill="currentColor"
    />
    <path
      d="M59.9151 25.5538V19.4818C59.9151 16.8658 61.2591 15.1378 63.6351 15.1378C65.5551 15.1378 66.7791 16.0978 66.7791 18.8098V25.5538H68.4351V18.4498C68.4351 15.5218 67.1151 13.6258 63.9231 13.6258C62.2431 13.6258 60.7071 14.4658 59.9391 15.9538L59.6991 13.9378H58.2591V25.5538H59.9151Z"
      fill="currentColor"
    />
    <path
      d="M78.512 25.5538H85.904C89.744 25.5538 91.856 23.8018 91.856 20.6578C91.856 18.6418 90.92 17.2738 89.168 16.6978C90.776 16.0018 91.64 14.6098 91.64 12.7138C91.64 9.66576 89.48 7.79376 85.904 7.79376H78.512V25.5538ZM85.496 11.2018C86.912 11.2018 87.656 11.8498 87.656 13.0978C87.656 14.3698 86.888 15.0658 85.496 15.0658H82.4V11.2018H85.496ZM85.688 18.3298C87.104 18.3298 87.872 18.9778 87.872 20.1778C87.872 21.4978 87.128 22.1458 85.688 22.1458H82.4V18.3298H85.688Z"
      fill="currentColor"
    />
    <path
      d="M97.7129 25.5538V7.45776H94.0169V25.5538H97.7129Z"
      fill="currentColor"
    />
    <path
      d="M99.8376 19.5298C99.8376 23.3218 102.622 25.8418 106.462 25.8418C110.278 25.8418 113.062 23.3218 113.062 19.5298C113.062 15.7378 110.278 13.1938 106.462 13.1938C102.622 13.1938 99.8376 15.7378 99.8376 19.5298ZM103.558 19.5058C103.558 17.7058 104.734 16.5058 106.462 16.5058C108.166 16.5058 109.342 17.7058 109.342 19.5058C109.342 21.3298 108.166 22.5298 106.462 22.5298C104.734 22.5298 103.558 21.3298 103.558 19.5058Z"
      fill="currentColor"
    />
    <path
      d="M114.556 19.5058C114.556 23.3458 117.004 25.8658 120.796 25.8658C124.156 25.8658 126.604 23.8018 126.844 20.7778H123.124C122.884 21.9538 122.092 22.5538 120.796 22.5538C119.212 22.5538 118.276 21.4258 118.276 19.5058C118.276 17.6578 119.284 16.4818 120.844 16.4818C122.116 16.4818 122.86 17.0578 123.124 18.2578H126.844C126.604 15.1138 124.252 13.1698 120.7 13.1698C117.1 13.1698 114.556 15.8098 114.556 19.5058Z"
      fill="currentColor"
    />
    <path
      d="M132.564 25.5538V22.5538L133.98 21.0658L136.668 25.5538H140.94L136.572 18.3058L141.084 13.5538H136.452L132.564 17.8738V7.45776H128.868V25.5538H132.564Z"
      fill="currentColor"
    />
    <path
      d="M141.622 21.7618C141.622 24.1858 143.614 25.8658 146.782 25.8658C149.902 25.8658 152.086 24.3058 152.086 21.7378C152.086 19.8178 151.006 18.9058 149.038 18.4738L146.806 17.9938C145.966 17.8018 145.51 17.4898 145.51 16.9378C145.51 16.2898 146.038 15.8578 146.878 15.8578C147.766 15.8578 148.27 16.4098 148.27 17.2258H151.774C151.774 14.8018 149.83 13.1698 147.046 13.1698C144.166 13.1698 142.03 14.6098 142.03 17.1058C142.03 19.2178 143.11 20.1778 145.318 20.6578L147.526 21.1378C148.414 21.3298 148.606 21.7138 148.606 22.0738C148.606 22.6978 148.078 23.1058 146.926 23.1058C145.798 23.1058 145.174 22.6018 145.126 21.7618H141.622Z"
      fill="currentColor"
    />
  </svg>
);
