import type { SVGProps } from "react";

export const Home: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6461 4.05002L4.90859 9.30002C3.78359 10.175 2.87109 12.0375 2.87109 13.45V22.7125C2.87109 25.6125 5.23359 27.9875 8.13359 27.9875H22.6086C25.5086 27.9875 27.8711 25.6125 27.8711 22.725V13.625C27.8711 12.1125 26.8586 10.175 25.6211 9.31252L17.8961 3.90002C16.1461 2.67502 13.3336 2.73752 11.6461 4.05002Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
    <path
      d="M15.3711 22.9875V19.2375"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    />
  </svg>
);
