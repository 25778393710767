import type { SVGProps } from "react";

export const Sun: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1211 24.1033C19.6084 24.1033 23.2461 20.4656 23.2461 15.9783C23.2461 11.491 19.6084 7.85333 15.1211 7.85333C10.6338 7.85333 6.99609 11.491 6.99609 15.9783C6.99609 20.4656 10.6338 24.1033 15.1211 24.1033Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
      fill="currentColor"
    />
    <path
      d="M24.0461 24.9033L23.8836 24.7408M23.8836 7.21583L24.0461 7.05333L23.8836 7.21583ZM6.19609 24.9033L6.35859 24.7408L6.19609 24.9033ZM15.1211 3.57833V3.47833V3.57833ZM15.1211 28.4783V28.3783V28.4783ZM2.72109 15.9783H2.62109H2.72109ZM27.6211 15.9783H27.5211H27.6211ZM6.35859 7.21583L6.19609 7.05333L6.35859 7.21583Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      fill="currentColor"
    />
  </svg>
);
