import type { SVGProps } from "react";

export const Copylink: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 22 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_129_6388)">
      <path
        d="M2.22703 10.5C1.43703 9.55 0.957031 8.33 0.957031 7C0.957031 3.98 3.42703 1.5 6.45703 1.5H11.457C14.477 1.5 16.957 3.98 16.957 7C16.957 10.02 14.487 12.5 11.457 12.5H8.95703"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
      <path
        d="M19.687 10.5C20.477 11.45 20.957 12.67 20.957 14C20.957 17.02 18.487 19.5 15.457 19.5H10.457C7.43703 19.5 4.95703 17.02 4.95703 14C4.95703 10.98 7.42703 8.5 10.457 8.5H12.957"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_129_6388">
        <rect
          height="20"
          width="21"
          fill="currentColor"
          transform="matrix(-1 0 0 1 21.457 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
