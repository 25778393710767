import type { SVGProps } from "react";

export const Refresh: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9663 8.49998C15.9663 12.3215 12.8647 15.4231 9.04319 15.4231C5.22166 15.4231 2.88858 11.5738 2.88858 11.5738M2.88858 11.5738H6.01781M2.88858 11.5738V15.0354M2.12012 8.49998C2.12012 4.67844 5.19396 1.5769 9.04319 1.5769C13.6609 1.5769 15.9663 5.42614 15.9663 5.42614M15.9663 5.42614V1.9646M15.9663 5.42614H12.8924"
      stroke="currentColor"
      strokeWidth="1.03846"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
