import type { SVGProps } from "react";

export const Logo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 35"
    fill="none"
    {...props}
  >
    <path
      stroke="#DC724C"
      strokeLinecap="round"
      strokeWidth={4.016}
      d="M2.962 24.117 34.666 2.444M2.963 31.695l31.704-21.674"
    />
    <circle
      cx={18.351}
      cy={16.811}
      r={12.578}
      fill="#DC724C"
      stroke="#DC724C"
      strokeWidth={1.179}
    />
    <path
      fill="#15181C"
      stroke="#15181C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.563}
      d="M17.06 10.55v11.052h2.835V10.55c0-.676-.284-1.228-1.134-1.228h-.568c-.85 0-1.134.552-1.134 1.228ZM11.935 14.234v7.368h2.52v-7.368c0-.675-.252-1.228-1.008-1.228h-.504c-.756 0-1.008.553-1.008 1.228ZM22.499 17.304v4.298h2.52v-4.298c0-.676-.251-1.228-1.008-1.228h-.504c-.756 0-1.008.552-1.008 1.228Z"
    />
  </svg>
);
