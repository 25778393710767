import type { SVGProps } from "react";

export const ArrowLeft: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4827 12.5V15.17C17.4827 18.48 15.1327 19.84 12.2627 18.18L9.95273 16.84L7.64273 15.5C4.77273 13.84 4.77273 11.13 7.64273 9.46999L9.95273 8.12999L12.2627 6.78999C15.1327 5.15999 17.4827 6.50999 17.4827 9.82999V12.5Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
